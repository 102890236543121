import { TIMEOUT_SEC } from "./constants";

// Token Helper
export function getToken() {
  const token = getCookie("access_token");
  return token ? `Bearer ${token}` : null;
}

// Get cookie value by name
export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}

// Timeout Helper
const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timeout after ${s} second`));
    }, s * 1000);
  });
};

// Reusable API Request Handler
export const fetchRequest = async function (url, options = {}) {
  try {
    const res = await Promise.race([fetch(url, options), timeout(TIMEOUT_SEC)]);
    const data = await res.json();

    if (!res.ok) throw new Error(`${data.message} (${res.status})`);

    return data;
  } catch (err) {
    console.error(err.message);
    throw err;
  }
};

// POST Request with JSON Body
export const postJSON = async function (url, uploadData, useAuth = false) {
  const headers = {
    "Content-Type": "application/json",
  };

  if (useAuth) headers.Authorization = getToken();

  const options = {
    method: "POST",
    headers,
    body: JSON.stringify(uploadData),
  };

  return await fetchRequest(url, options);
};

// POST Request with FormData (File Upload)
export const postFormData = async function (url, formData, useAuth = true) {
  const headers = {};

  if (useAuth) headers.Authorization = getToken();

  const options = {
    method: "POST",
    headers,
    body: formData,
  };

  return await fetchRequest(url, options);
};

export const deletePostHelper = async function (url, useAuth = true) {
  const headers = {};

  if (useAuth) headers.Authorization = getToken();

  const options = {
    method: "DELETE",
    headers,
  };

  return await fetchRequest(url, options);
};

// GET Request with optional Authorization
export const getWithAuth = async function (url) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: getToken(),
  };

  const options = {
    method: "GET",
    headers,
  };

  return await fetchRequest(url, options);
};
