import { useEffect, useState } from "react";
import Button from "./Button";
import Form from "./Form";
import FormRow from "./FormRow";
import { getPosts, uploadExcel2 } from "../services/apiFunctions";
import Input from "./Input";
import styled from "styled-components";
import toast from "react-hot-toast";
import Loader from "./Loader";

const H3 = styled.h3`
  color: var(--color-grey-700);
  text-align: center;
  margin-bottom: 3.2rem;
`;

function UploadExcel({ onCloseModal, setIsLoadingOverlay, isLoading }) {
  const [postName, setPostName] = useState("");
  const [file, setFile] = useState(null);
  const [posts, setPosts] = useState(null);

  useEffect(function () {
    async function loadPosts() {
      const data = await getPosts();
      setPosts(() => data.posts);
      console.log(data.posts);
    }

    loadPosts();
    setFile(null);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    onCloseModal();
    if (postName !== undefined && file !== undefined && file !== null) {
      setIsLoadingOverlay(true);
      const data = {
        name: postName,
      };

      uploadExcel2(data).then((data) => {
        console.log(data);
        if (data?.message) {
          toast.success(`${data.count} users inserted successfully`);
        }
      });

      setFile(null);
      setIsLoadingOverlay(false);
      setPostName("");
    }
  }

  return (
    <div>
      <H3>Upload excel file that contains all users information's</H3>
      <Form type="modal" onSubmit={handleSubmit}>
        <FormRow label="File">
          <Input
            type="file"
            id="file"
            disabled={false}
            onChange={(e) => setFile(e.target.files[0])}
          />
        </FormRow>
        <FormRow label="Name">
          <Input
            type="text"
            id="name"
            disabled={false}
            onChange={(e) => setPostName(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <Button disabled={false}>Confirm</Button>
        </FormRow>
      </Form>
    </div>
  );
}

export default UploadExcel;
