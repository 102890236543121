import { useEffect, useState } from "react";
import Button from "./Button";
import Form from "./Form";
import FormRow from "./FormRow";
import { getPosts, uploadExcel } from "../services/apiFunctions";
import Input from "./Input";
import styled from "styled-components";
import Select from "./Select";
import Option from "./Option";

const H3 = styled.h3`
  color: var(--color-grey-700);
  text-align: center;
  margin-bottom: 3.2rem;
`;

function UploadExcel({ onCloseModal }) {
  const [postID, setPostID] = useState("");
  const [file, setFile] = useState(null);
  const [posts, setPosts] = useState(null);

  useEffect(function () {
    async function loadPosts() {
      const data = await getPosts();
      setPosts(() => data.posts);
      console.log(data.posts);
    }

    loadPosts();
    setFile(null);
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (postID !== undefined && file !== null) {
      const data = {
        post_id: postID,
        file,
      };
      uploadExcel(data).then((data) => console.log(data));
      console.log(data);
      onCloseModal();
      setFile(null);
      setPostID("");
    }
  }

  return (
    <div>
      <H3>Upload excel file that contains all users information's</H3>
      <Form type="modal" onSubmit={handleSubmit}>
        <FormRow label="name">
          <Input
            type="file"
            id="file"
            disabled={false}
            onChange={(e) => setFile(e.target.files[0])}
          />
        </FormRow>
        <FormRow label="type">
          <Select
            value={postID}
            id="post_id"
            disabled={false}
            onChange={(e) => setPostID(e.target.value)}
          >
            <Option selected>Select post...</Option>
            {posts?.map((post, i) => (
              <Option key={post.id} value={post.id}>
                {post.name}
              </Option>
            ))}
          </Select>
        </FormRow>
        <FormRow>
          <Button disabled={false}>Confirm</Button>
        </FormRow>
      </Form>
    </div>
  );
}

export default UploadExcel;
