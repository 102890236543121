import styled from "styled-components";
import Spinner from "./Spinner";

const LoaderOverlay = styled.div`
  position: fixed; /* Change to fixed to prevent scrolling */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(
    108,
    122,
    137,
    0.2
  ); /* Corresponds to bg-slate-200/20 */
  backdrop-filter: blur(4px); /* Corresponds to backdrop-blur-sm */
  z-index: 9999; /* Ensures it's on top of other elements */
`;

function Loader({ children, onClick }) {
  return (
    <LoaderOverlay onClick={onClick}>
      {children}
      {children === undefined ? <Spinner /> : ""}
    </LoaderOverlay>
  );
}

export default Loader;
