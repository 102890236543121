import styled from "styled-components";

const Div = styled.div`
  min-height: 80vh;
  h1 {
    text-align: center;
    font-size: 5.8rem;
    margin-top: 6rem;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
  }

  ul {
    font-size: 1.6rem;
    li {
      margin-bottom: 1.2rem;
    }
  }
`;

function BeforeLogin() {
  return (
    <Div>
      <h1>Welcome Admin!</h1>
      <p>
        After logging in, you can manage your platform with ease. Here’s what
        you can do:
      </p>

      <ul>
        <li>
          <strong>Create Posts:</strong> Share important updates or
          announcements by creating posts directly from your dashboard.
        </li>
        <li>
          <strong>Upload User Data:</strong> Seamlessly upload an Excel file
          containing all user information for efficient management.
        </li>
        <li>
          <strong>Start a Lottery:</strong> Run exciting lotteries for
          Instagram, either for a specific post or the entire page, to engage
          your audience and reward participants.
        </li>
      </ul>

      <p>Take control and streamline your tasks all in one place!</p>
    </Div>
  );
}

export default BeforeLogin;
