import styled from "styled-components";
import Form from "./Form";
import FormRow from "./FormRow";
import Input from "./Input";
import { useState } from "react";
import Button from "./Button";
import Spinner from "./Spinner";
import { login } from "../services/apiFunctions";

const Header = styled.h3`
  color: var(--color-grey-500);
  text-align: center;
  margin-bottom: 2rem;
`;

function LoginForm({ onCloseModal, setIsLoggedin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    if (username !== undefined && password !== undefined) {
      setIsLoading(true);
      const user = {
        username,
        password,
      };
      login(user).then((data) => {
        console.log(data);
        if (data.access_token) setIsLoggedin(true);
      });
      setIsLoading(false);
      onCloseModal();
    }
  }

  if (isLoading) return <Spinner />;

  return (
    <>
      <Header>Log IN</Header>
      <Form type="modal" onSubmit={handleSubmit}>
        <FormRow label="username">
          <Input
            value={username}
            type="text"
            id="username"
            disabled={isLoading}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormRow>
        <FormRow label="password">
          <Input
            value={password}
            type="password"
            id="password"
            disabled={isLoading}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <Button disabled={isLoading}>login</Button>
        </FormRow>
      </Form>
    </>
  );
}

export default LoginForm;
