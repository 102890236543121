import styled from "styled-components";

const Div = styled.div`
  background-color: var(--color-brand-500);
  color: var(--color-grey-0);
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  margin-top: 4.8rem;

  p {
    text-align: center;
    font-size: 1rem;
    padding: 1.2rem;
  }
`;

function Footer() {
  return (
    <>
      <Div>
        <p>Copyright 2024, All Rights Reserved Mond Trades Ltd</p>
      </Div>
    </>
  );
}

export default Footer;
