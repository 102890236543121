import styled from "styled-components";
import AnimatedButton from "./AnimatedButton";
import Modal from "./Modal";
import CreatePost from "./CreatePost";
import CreatePost2 from "./CreatePost2";
import UploadExcel from "./UploadExcel";
import UploadExcel2 from "./UploadExcel2";
import Lottery from "./Lottery";
import { useState } from "react";
import LotteryInfo from "./LotteryInfo";
import Loader from "./Loader";

const MainActions = styled.div`
  display: flex;
  justify-content: space-around;
  /* margin-bottom: 4rem; */
`;

function AfterLogin() {
  const [isLotteryTabOpen, setIsLotteryTabOpen] = useState(false);
  const [isNewPostInserted, setIsNewPostInserted] = useState(false);
  const [isNewPostCreated, setIsNewPostCreated] = useState(false);
  const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);

  // if (isLoading) return <Loader />;

  return (
    <>
      {!isLotteryTabOpen && (
        <MainActions>
          <Modal>
            <Modal.Open opens="create-post">
              <AnimatedButton>Create Post</AnimatedButton>
            </Modal.Open>
            <Modal.Window name="create-post">
              <CreatePost setIsNewPostCreated={setIsNewPostCreated} />
            </Modal.Window>
            <Modal.Open opens="upload-excel">
              <AnimatedButton>Upload Excel</AnimatedButton>
            </Modal.Open>
            <Modal.Window name="upload-excel">
              <UploadExcel />
            </Modal.Window>
          </Modal>

          <AnimatedButton onClick={() => setIsLotteryTabOpen((open) => !open)}>
            Start Lottery
          </AnimatedButton>
        </MainActions>
      )}
      {isLotteryTabOpen ? (
        <>
          <MainActions>
            <Modal>
              <Modal.Open opens="create-post">
                <AnimatedButton>Insert Posts</AnimatedButton>
              </Modal.Open>
              <Modal.Window name="create-post">
                <CreatePost2
                  setIsNewPostInserted={setIsNewPostInserted}
                  setIsLoadingOverlay={setIsLoadingOverlay}
                />
              </Modal.Window>
              <Modal.Open opens="upload-excel">
                <AnimatedButton>Upload Excel</AnimatedButton>
              </Modal.Open>
              <Modal.Window name="upload-excel">
                <UploadExcel2
                  setIsNewPostInserted={setIsNewPostInserted}
                  setIsLoadingOverlay={setIsLoadingOverlay}
                />
              </Modal.Window>
            </Modal>

            <AnimatedButton
              onClick={() => setIsLotteryTabOpen((open) => !open)}
            >
              Back
            </AnimatedButton>
          </MainActions>
          {isLoadingOverlay && <Loader />}
          <Lottery
            setIsLotteryTabOpen={setIsLotteryTabOpen}
            isNewPostInserted={isNewPostInserted}
            setIsNewPostInserted={setIsNewPostInserted}
            isLoadingOverlay={isLoadingOverlay}
          />
        </>
      ) : (
        <LotteryInfo
          isNewPostCreated={isNewPostCreated}
          setIsNewPostCreated={setIsNewPostCreated}
        />
      )}
    </>
  );
}

export default AfterLogin;
