import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { getPosts2, getUsers, getWinnerUser } from "../services/apiFunctions";
import Spinner from "./Spinner";
import AnimatedButton from "./AnimatedButton";
import toast from "react-hot-toast";
import Loader from "./Loader";
import Logo from "./Logo";

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const Container = styled.div`
  background-color: var(--color-grey-0);
  max-width: 100%;
  min-height: 80vh;
  color: var(--color-grey-700);
  border-top: 1px solid var(--color-grey-200);
  margin-bottom: 10rem;

  & > div {
    max-width: 120rem;
    margin-top: 4rem;
  }

  .lottery-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 6rem 0;
    gap: 2rem;
  }

  h2 {
    font-size: 2.6rem;
    margin: 6.4rem 0;
    text-align: center;
  }

  p {
    font-size: 1.8rem;
  }

  .posts h4 {
    margin-bottom: 1.2rem;
  }

  .post {
    border-bottom: 1px solid var(--color-grey-200);
    padding: 1.2rem;
    border-radius: 9px;
    cursor: pointer;

    &:hover {
      background-color: var(--color-grey-100);
    }
  }

  .winner {
    align-self: center;
    justify-self: center;

    & .users-winner {
      h3 {
        margin-left: 2rem;
      }
    }
    h4 {
      margin: 2rem 0 0 0;
      text-align: center;
    }

    .loop-user {
      font-size: 6.4rem;
      color: var(--color-brand-500);
    }
  }
`;

function Lottery({ setIsLotteryTabOpen, isNewPostInserted, isLoadingOverlay }) {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingWinner, setIsLoadingWinner] = useState(false);
  const [activePostId, setActivePostId] = useState("");
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(""); // Holds the name of the user during the animation
  const [winner, setWinner] = useState(null);
  const [isWinnerLoaderVisible, setIsWinnerLoaderVisible] = useState(false); // State for controlling the winner loader

  useEffect(() => {
    async function loadPosts() {
      setIsLoading(true);
      try {
        const data = await getPosts2();
        setPosts(data.posts);
      } catch (error) {
        console.error("Failed to load posts:", error);
      } finally {
        setIsLoading(false);
      }
    }
    loadPosts();
  }, [isNewPostInserted]);

  const startLotteryAnimation = (usersList) => {
    let index = 0;
    const intervalId = setInterval(() => {
      if (index === usersList.length) {
        index = 0;
      }
      setCurrentUser(index);
      index = index + 1;
    }, 10); // Show each user every 100ms

    // Stop after 5 seconds (5000ms) when the winner is returned
    setTimeout(() => {
      clearInterval(intervalId);
    }, 5000);
  };

  const handleClick = async () => {
    setWinner(() => "");
    setUsers([]);
    setIsWinnerLoaderVisible(false); // Initially hide the loader
    if (!activePostId) {
      toast.error("Please select a lottery");
      return;
    }

    setIsLoadingWinner(true);
    try {
      const data = await getUsers(activePostId);
      if (data.users) {
        setUsers(data.users);
        startLotteryAnimation(data.users); // Start the looping animation
      }

      // Simulate waiting for the winner after 5 seconds
      const winnerData = await getWinnerUser(activePostId);
      setWinner(() => winnerData);
      console.log(winnerData);
      setIsWinnerLoaderVisible(true); // Show the loader for the winner
    } catch (error) {
      console.error("Failed to load users or winner:", error);
    } finally {
      setIsLoadingWinner(false);
    }
  };

  const handleLoaderClick = () => {
    setIsWinnerLoaderVisible(false); // Hide the loader when the user clicks on it
  };

  const onlyPosts = posts.filter((post) => post.type === "post");
  const onlyPages = posts.filter((post) => post.type === "page");
  const onlyExcels = posts.filter((post) => post.type === "excel");

  return (
    <Container className="flex-center">
      {isLoading && !isLoadingOverlay && <Spinner />}
      <div>
        {!isLoading && (
          <>
            <h2>Start Your Instagram Lottery</h2>
            <p>
              Run engaging lotteries effortlessly! You can start a lottery for
              comments on a specific Instagram post or for interactions across
              your entire page. Choose your option and let the fun begin!
            </p>
            <div className="lottery-container">
              <div className="posts">
                <h4>Lottery for Posts</h4>
                {onlyPosts.map((post) => (
                  <div
                    className={`post ${
                      activePostId === post.id ? "active" : ""
                    }`}
                    style={
                      post.id === activePostId
                        ? { backgroundColor: "var(--color-grey-200)" }
                        : {}
                    }
                    key={post.id}
                    onClick={() => setActivePostId(post.id)}
                  >
                    <span>{post.name}</span>
                  </div>
                ))}
              </div>

              <div className="posts">
                <h4>Lottery for Instagram Pages</h4>
                {onlyPages.map((post) => (
                  <div
                    className={`post ${
                      activePostId === post.id ? "active" : ""
                    }`}
                    style={
                      post.id === activePostId
                        ? { backgroundColor: "var(--color-grey-200)" }
                        : {}
                    }
                    key={post.id}
                    onClick={() => setActivePostId(post.id)}
                  >
                    <span>{post.name}</span>
                  </div>
                ))}
              </div>
              <div className="posts">
                <h4>Lottery for Excel file</h4>
                {onlyExcels.map((post) => (
                  <div
                    className={`post ${
                      activePostId === post.id ? "active" : ""
                    }`}
                    style={
                      post.id === activePostId
                        ? { backgroundColor: "var(--color-grey-200)" }
                        : {}
                    }
                    key={post.id}
                    onClick={() => setActivePostId(post.id)}
                  >
                    <span>{post.name}</span>
                  </div>
                ))}
              </div>

              <div className="winner">
                <div className="users-winner">
                  {
                    <h3>
                      {winner?.username ? (
                        <>
                          Our lucky winner:{" "}
                          <span style={{ color: "var(--color-brand-500)" }}>
                            {winner?.username}
                          </span>
                        </>
                      ) : (
                        "Let's see who wins"
                      )}
                    </h3>
                  }
                  {!isLoadingWinner ? (
                    <h4>Select one option</h4>
                  ) : (
                    <Loader boolean={true}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "2rem",
                        }}
                      >
                        <Logo />
                        <p className="loop-user">
                          {users[currentUser]?.username}
                        </p>
                      </div>
                    </Loader>
                  )}
                  {isWinnerLoaderVisible && winner?.username && (
                    <Loader boolean={true} onClick={handleLoaderClick}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "2rem",
                          cursor: "pointer", // Add cursor pointer to indicate clickability
                        }}
                        onClick={handleLoaderClick} // Close loader on click
                      >
                        <Logo style={{ height: "20rem" }} />
                        {winner?.username ? (
                          <>
                            <span
                              style={{
                                color: "var(--color-grey-700)",
                                fontSize: "6.4rem",
                              }}
                            >
                              Our lucky winner 😍
                            </span>{" "}
                            <span
                              style={{
                                color: "var(--color-brand-500)",
                                fontSize: "6.4rem",
                              }}
                            >
                              {winner?.username}
                            </span>
                            <span
                              style={{
                                color: "var(--color-grey-700)",
                                fontSize: "4.8rem",
                              }}
                            >
                              comment: {winner?.comment}
                            </span>
                          </>
                        ) : (
                          "Let's see who wins"
                        )}
                      </div>
                    </Loader>
                  )}
                </div>
                <div className="btn">
                  <AnimatedButton onClick={() => handleClick()}>
                    Start
                  </AnimatedButton>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
}

export default Lottery;
