import { MAIN_URL } from "../utils/constants";
import {
  postJSON,
  postFormData,
  getWithAuth,
  deletePostHelper,
} from "../utils/helpers";
import { toast } from "react-hot-toast";

const handleResponse = (promise, successMessage, errorMessage) => {
  return promise
    .then((res) => {
      if (successMessage) toast.success(successMessage);
      return res;
    })
    .catch((e) => {
      if (errorMessage) toast.error(errorMessage);
      else toast.error(e.message);
    });
};

export const login = (user) => {
  return handleResponse(
    postJSON(`${MAIN_URL}/login`, user),
    "Login successful",
    "Username or password is incorrect"
  ).then((data) => {
    if (data?.access_token) {
      document.cookie = `access_token=${data.access_token};`;
    }
    console.log(data);
    return data;
  });
};

export const createPost = (data) => {
  return handleResponse(
    postJSON(`${MAIN_URL}/post/store`, data, true),
    "Post created successfully.",
    "Failed to create post"
  );
};

export const createPost2 = (data) => {
  return handleResponse(
    postJSON(`${MAIN_URL}/post/update`, data, true),
    "",
    "There is no post or excel file with provided name"
  );
};

export const uploadExcel = (data) => {
  const formData = new FormData();
  formData.append("post_id", data.post_id);
  formData.append("file", data.file);

  return handleResponse(
    postFormData(`${MAIN_URL}/upload`, formData, true),
    "Excel uploaded successfully.",
    "Failed to upload Excel"
  );
};
export const uploadExcel2 = (data) => {
  return handleResponse(
    postJSON(`${MAIN_URL}/post/update`, data, true),
    "",
    "There is no post or excel file with provided name"
  );
};

export const getPosts = () => {
  return handleResponse(getWithAuth(`${MAIN_URL}/posts/all`));
};

export const getPosts2 = () => {
  return handleResponse(getWithAuth(`${MAIN_URL}/posts/read`));
};

export const deletePost = (id) => {
  return handleResponse(deletePostHelper(`${MAIN_URL}/posts/${id}`));
};

export const getUsers = (id) => {
  return handleResponse(
    getWithAuth(`${MAIN_URL}/users?post_id=${id}`),
    "Users retrieved successfully.",
    "Failed to retrieve users"
  );
};

export const getWinnerUser = (id) => {
  return handleResponse(
    getWithAuth(`${MAIN_URL}/random?post_id=${id}`),
    "Winner retrieved successfully.",
    "Failed to retrieve winner"
  );
};
