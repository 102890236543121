import { useState } from "react";
import Form from "./Form";
import FormRow from "./FormRow";
import Input from "./Input";
import Button from "./Button";
import { createPost2 } from "../services/apiFunctions";
import styled from "styled-components";
import toast from "react-hot-toast";

const H3 = styled.h3`
  color: var(--color-grey-700);
  text-align: center;
  margin-bottom: 3.2rem;
`;

function CreatePost({
  onCloseModal,
  setIsNewPostInserted,
  setIsLoadingOverlay,
}) {
  const [postName, setPostName] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoadingOverlay(true);
    if (postName !== undefined) {
      const data = {
        name: postName,
      };
      createPost2(data).then((data) => {
        console.log(data);
        if (data?.message) {
          toast.success(`${data.count} users inserted successfully`);
          setIsNewPostInserted((b) => !b);
        }
        setIsLoadingOverlay(false);
      });

      onCloseModal();
    }
  }

  return (
    <div>
      <H3>Insert Post</H3>
      <Form type="modal" onSubmit={handleSubmit}>
        <FormRow label="Post URL">
          <Input
            value={postName}
            type="text"
            id="name"
            disabled={false}
            onChange={(e) => setPostName(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <Button disabled={false}>Confirm</Button>
        </FormRow>
      </Form>
    </div>
  );
}

export default CreatePost;
