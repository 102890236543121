import { BiLogoInstagramAlt } from "react-icons/bi";
import {
  FaLinkedin,
  FaTelegramPlane,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { TiLocationOutline } from "react-icons/ti";
import styled from "styled-components";

const Container = styled.div`
  font-size: 1.2rem;
  background-color: var(--color-brand-500);
  width: 100%;
  padding: 2rem 0;

  & > div {
    max-width: 120rem;
    margin: 0 auto;
  }

  .mondfx {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mondfx__info {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }

  .mondfx__info--part {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
  }

  .mondfx-icons {
    display: flex;
    gap: 1.6rem;
    font-size: 1.8rem;
  }
`;

function AppTopLinks() {
  return (
    <Container>
      <div className="mondfx">
        <div className="mondfx__info">
          <div className="mondfx__info--part">
            <TiLocationOutline />
            <span>
              Ground Floor, The Sotheby Building, Rodney Bay, Saint Lucia
            </span>
          </div>
          <div className="mondfx__info--part">
            <MdOutlineMailOutline />
            <span>info@mondfx.com</span>
          </div>
        </div>
        <div className="mondfx-icons">
          <div>
            <BiLogoInstagramAlt />
          </div>
          <div>
            <FaTelegramPlane />
          </div>
          <div>
            <FaYoutube />
          </div>
          <div>
            <FaTwitter />
          </div>
          <div>
            <FaLinkedin />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default AppTopLinks;
