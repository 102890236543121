import styled from "styled-components";
import Header from "./Header";
import AppTopLinks from "./AppTopLinks";
import BeforeLogin from "./BeforeLogin";
import AfterLogin from "./AfterLogin";
import { useState, useEffect } from "react";
import { getToken } from "../utils/helpers";
import Footer from "./Footer";

const LayoutContainer = styled.div`
  max-width: 100rem;
  margin: 0 auto;
  color: var(--color-grey-700);
`;

function AppLayout() {
  const [isLoggedin, setIsLoggedin] = useState(false);

  useEffect(() => {
    const accessToken = getToken();
    if (accessToken) {
      setIsLoggedin(true);
    } else {
      setIsLoggedin(false);
    }
  }, []);

  return (
    <>
      <AppTopLinks />
      <Header
        className="main-header"
        isLoggedin={isLoggedin}
        setIsLoggedin={setIsLoggedin}
      />
      {isLoggedin ? (
        <AfterLogin />
      ) : (
        <LayoutContainer>
          <BeforeLogin />
        </LayoutContainer>
      )}

      <Footer />
    </>
  );
}

export default AppLayout;
