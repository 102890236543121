import styled from "styled-components";

const Image = styled.img`
  height: 10rem;
`;

function Logo() {
  return (
    <div>
      <Image src="MondFx-logo-header.png" alt="MondFx logo image" />
    </div>
  );
}

export default Logo;
