import styled from "styled-components";
import Logo from "./Logo";
import { CgProfile } from "react-icons/cg";
import Modal from "./Modal";
import LoginForm from "./LoginForm";

const HeaderContainer = styled.div`
  border-bottom: 1px solid var(--color-grey-200);

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    max-width: 120rem;
    margin: 0 auto;

    & .login {
      font-size: 1.8rem;
      text-transform: uppercase;
      color: var(--color-brand-500);
      display: flex;
      align-items: center;
      gap: 0.6rem;
      cursor: pointer;
      padding: 0.8rem 1.6rem;
      border-radius: var(--border-radius-lg);

      &:hover {
        background-color: var(--color-grey-200);
      }
    }
  }
`;

function Header({ isLoggedin, setIsLoggedin }) {
  return (
    <HeaderContainer>
      <div>
        <Logo />
        <Modal>
          <Modal.Open opens="login-form">
            <div className="login">
              <CgProfile />
              <span>{isLoggedin ? "admin" : "log in"}</span>
            </div>
          </Modal.Open>
          <Modal.Window name="login-form">
            <LoginForm setIsLoggedin={setIsLoggedin} />
          </Modal.Window>
        </Modal>
      </div>
    </HeaderContainer>
  );
}

export default Header;
