import { useState } from "react";
import Form from "./Form";
import FormRow from "./FormRow";
import Input from "./Input";
import Select from "./Select";
import Option from "./Option";
import Button from "./Button";
import { createPost } from "../services/apiFunctions";
import styled from "styled-components";

const H3 = styled.h3`
  color: var(--color-grey-700);
  text-align: center;
  margin-bottom: 3.2rem;
`;

function CreatePost({ onCloseModal, setIsNewPostCreated }) {
  const [postName, setPostName] = useState("");
  const [type, setType] = useState("post");

  function handleSubmit(e) {
    e.preventDefault();
    if (postName !== undefined && type !== undefined) {
      const data = {
        name: postName,
        type,
      };
      createPost(data).then((data) => {
        console.log(data);
        setIsNewPostCreated((d) => !d);
      });

      onCloseModal();
    }
  }

  return (
    <div>
      <H3>Insert Post</H3>
      <Form type="modal" onSubmit={handleSubmit}>
        <FormRow label="Post URL">
          <Input
            value={postName}
            type="text"
            id="name"
            disabled={false}
            onChange={(e) => setPostName(e.target.value)}
          />
        </FormRow>
        <FormRow label="Type">
          <Select
            value={type}
            id="type"
            disabled={false}
            onChange={(e) => setType(e.target.value)}
          >
            <Option value="post">Post</Option>
            <Option value="page">Page</Option>
            <Option value="excel">Excel</Option>
          </Select>
        </FormRow>
        <FormRow>
          <Button disabled={false}>Confirm</Button>
        </FormRow>
      </Form>
    </div>
  );
}

export default CreatePost;
