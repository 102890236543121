import styled from "styled-components";

const Button = styled.button`
  color: var(--color-grey-0);
  background-color: var(--color-brand-500);
  margin: 2rem;
  padding: 1rem 0;
  min-width: 28rem;
  text-align: center;
  border: none;
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow-md);
  cursor: pointer;
  transition: transform 0.1s ease-in-out, box-shadow 0.2s ease-in-out,
    background-color 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: var(--color-brand-600);
  }

  &:focus {
    outline: none;
  }
`;

function AnimatedButton({ children, onClick }) {
  return <Button onClick={onClick}>{children}</Button>;
}

export default AnimatedButton;
