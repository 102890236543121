import { useEffect, useState } from "react";
import styled from "styled-components";
import { deletePost, getPosts, getPosts2 } from "../services/apiFunctions";
import Button from "./Button";
import Spinner from "./Spinner";
import toast from "react-hot-toast";

const Container = styled.div`
  max-width: 100%;
  min-height: 80vh;
  color: var(--color-grey-700);
  padding: 0 6.4rem;
  border-top: 1px solid var(--color-grey-200);
  margin-bottom: 10rem;
  /* text-align: center; */

  h2 {
    font-size: 2.8rem;
    margin: 3rem 0;
  }
  P {
    margin-bottom: 4.8rem;
  }
  h3 {
    margin-bottom: 3.2rem;
  }

  ul li {
    max-width: 40rem;
    border-radius: var(--color-grey-700);
    padding: 0.6rem 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

function LotteryInfo({ isNewPostCreated }) {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bol, setBol] = useState(false);

  useEffect(() => {
    async function loadPosts() {
      setIsLoading(true);
      try {
        const data = await getPosts();
        setPosts(data.posts);
      } catch (error) {
        console.error("Failed to load posts:", error);
      } finally {
        setIsLoading(false);
        console.log(posts);
      }
    }
    loadPosts();
  }, [bol, isNewPostCreated]);

  async function handleDelete(id) {
    const res = await deletePost(id);
    if (res.message) {
      setBol((b) => !b);
      toast.success(res.message);
    }

    console.log(res);
  }

  return (
    <Container>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <h2>How it works</h2>
          <p>
            To start a lottery, first, click on <strong>Create Post</strong> to
            set up your lottery event. Then, click on{" "}
            <strong>Upload Excel</strong> and select the relevant Instagram post
            by uploading the file containing the user information. Once the file
            is uploaded, you can manage all posts or choose to start a lottery
            for a specific post or for your entire Instagram page by clicking{" "}
            <strong>Start Lottery</strong>.
          </p>
          <h3>Created posts</h3>
          <ul>
            {posts.map((post) => (
              <li>
                <span>{post.name}</span>
                <Button
                  size="small"
                  variation="secondary"
                  onClick={() => handleDelete(post.id)}
                >
                  Delete
                </Button>
              </li>
            ))}
          </ul>
        </>
      )}
    </Container>
  );
}

export default LotteryInfo;
